import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/bai_cha',
    name: 'BaiCha',
    component: () => import('../views/bai_cha.vue'),
    meta: {
      title: 'HOOOOK宇宙之光'
    }
  },
  {
    path: '/han_geng',
    name: 'HanGeng',
    component: () => import('../views/han_geng.vue'),
    meta: {
      title: '未知的边界'
    }
  }
]

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router
